// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.logo {
    height: 50px;
}

.footersociallink {
    float: left;
    text-decoration: none;
    margin-right: 5px;
}

.linkclientes,.linkclientes:hover {
    font-weight: 200;
    font-size: 13px;
    color: #444;
    text-decoration: none;

    h5 {
        font-size: inherit;
    }
}